// import React from 'react';
// import NavigationBar from './Home/NavigationBar';
// import UpcomingMatches from './Home/UpcomingMatches';
// import LiveMatches from './Home/LiveMatches';
// import LiveVideo from './Home/LiveVideo';
// import LiveMatchDetails from './Home/LiveMatchDetails';
// import Footer from './Home/Footer';

// function App() {
//   return (
//     <>
//       <NavigationBar />
//       <UpcomingMatches />
//       <LiveMatches />
//       {/* <LiveMatchDetails /> */}
//       <Footer />
//     </>
//   );
// }

// export default App;


import React from 'react';
import Routes from './Routes';
import './styles/styles.css';
import { HelmetProvider } from 'react-helmet-async';
const App = () => {
  return (
    <HelmetProvider>
    <div className="app">
      <Routes />
    </div>
    </HelmetProvider>
  );
};

export default App;

