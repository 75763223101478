import React, { useState, useEffect, useContext, Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { API_URL } from './config';
const ProtectedRoute = ({ children }) => {
  const token = Cookies.get('auth_token');
  useEffect(() => {
    const validateToken = async (token) => {
      try {
        const response = await axios.get(`${API_URL}/validate_token.php`, {
          params: { token }
        });
        const { status } = response.data;
        if (status !== 'success') {
          Cookies.remove('auth_token');
          window.location.href = '/login';
        }
      } catch (error) {
        console.error('Error validating token:', error);
        Cookies.remove('auth_token');
        window.location.href = '/login';
      }
    };

    const token = Cookies.get('auth_token');
    if (token) {
      validateToken(token);
    } else {
      window.location.href = '/login'; 
    }
  }, []);
  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
