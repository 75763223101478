// import React, { useState, useEffect, useContext, Suspense } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { HelmetProvider } from 'react-helmet-async';
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import { ThemeProvider } from './Home/ThemeContext';
// import PreloaderCircle from './components/PreloaderCircle';
// import { API_URL } from './config';

// const Header = React.lazy(() => import('./Home/NavigationBar'));
// const Main = React.lazy(() => import('./Home/HomeMain'));
// const MatchDetail = React.lazy(() => import('./Home/MatchDetails'));
// const Footer = React.lazy(() => import('./Home/Footer'));
// const Login = React.lazy(() => import('./Home/Login'));
// const Register = React.lazy(() => import('./Home/Register'));
// const AdminPanel = React.lazy(() => import('./Admin/AdminPanel'));
// const Dashboard = React.lazy(() => import('./Admin/Dashboard'));
// const Settings = React.lazy(() => import('./Admin/Settings'));
// const Postlist = React.lazy(() => import('./Admin/Postlist'));
// const SectionList = React.lazy(() => import('./Admin/SectionList'));
// const Post = React.lazy(() => import('./Admin/Posts'));
// const EditPost = React.lazy(() => import('./Admin/EditPost'));
// const Users = React.lazy(() => import('./Admin/Users'));
// const LandingPage = React.lazy(() => import('./Home/LandingPage'));
// const NotFoundPage = React.lazy(() => import('./Home/NotFoundPage'));
// const AdminLayout = React.lazy(() => import('./Admin/AdminLayout'));

// const HeaderManagement  = React.lazy(() => import('./Admin/HeaderManagement'));
// const FooterManagement  = React.lazy(() => import('./Admin/FooterManagement'));
// const ContentSectionManagement  = React.lazy(() => import('./Admin/ContentSectionManagement'));


// const App = () => {
  
//   useEffect(() => {
//     const validateToken = async (token) => {
//       try {
//         const response = await axios.get(`${API_URL}/validate_token.php`, {
//           params: { token: token }
//         });

//         const { status, message, data } = response.data;
//         console.log(response.data)
//         if (status === 'success') {
//           console.log('Token is valid:', data);
         
//         } else {
//           console.error('Token validation failed:', message);
          
//         }
//       } catch (error) {
//         console.error('Error validating token:', error);
//       }
//     };

//     const token = Cookies.get('auth_token');
//     if (token) {
//       validateToken(token);
//     } else {
//       console.error('No token found');
//     }
//   }, []);

//   return (
//     <HelmetProvider>
//       <ThemeProvider>
//         <Router>
//           <Suspense fallback={<PreloaderCircle />}>
//             <Routes>
//               <Route path="/*" element={<Home />} />
//               <Route path="/admin/*" element={<Admin />} />
//               <Route path="*" element={<NotFound />} />
//             </Routes>
//           </Suspense>
//         </Router>
//       </ThemeProvider>
//     </HelmetProvider>
//   );
// };


// const Home = () => (
//   <>
//     <PreloaderCircle /> 
//     <Suspense fallback={<div>Loading...</div>}>
//         <Header/>
//         <Routes>
//           <Route path="/" element={<Main />} />
//           <Route path="/match/:id" element={<MatchDetail />} /> 
//           <Route path="*" element={<NotFoundPage />} />
//         </Routes>
//         <Footer/>
      
//     </Suspense>
//   </>
// );

// // Admin Component
// const Admin = () => (
//   <>
//     <Routes>
//       <Route element={<AdminLayout />}>
//         <Route path="/" element={<Suspense fallback={<PreloaderCircle />}><AdminPanel /></Suspense>} />
//         <Route path="/dashboard" element={<Suspense fallback={<PreloaderCircle />}><Dashboard /></Suspense>} />
//         <Route path="/settings" element={<Suspense fallback={<PreloaderCircle />}><Settings /></Suspense>} />
//         <Route path="/footer" element={<Suspense fallback={<PreloaderCircle />}><FooterManagement /></Suspense>} />
//         <Route path="/header" element={<Suspense fallback={<PreloaderCircle />}><HeaderManagement /></Suspense>} />
//         <Route path="/section" element={<SectionList />}></Route>
//         <Route path="/add-section" element={<Suspense fallback={<PreloaderCircle />}><ContentSectionManagement /></Suspense>} />
//         <Route path="/post" element={<Suspense fallback={<PreloaderCircle />}><Postlist /></Suspense>} />
//         <Route path="/add-post" element={<Suspense fallback={<PreloaderCircle />}><Post /></Suspense>} />
//         <Route path="/edit-post/:id" element={<Suspense fallback={<PreloaderCircle />}><EditPost /></Suspense>} />
//         <Route path="/users" element={<Users />} />
//         <Route path="*" element={<Suspense fallback={<PreloaderCircle />}><NotFoundPage /></Suspense>} />
//       </Route>
//     </Routes>
//   </>
// );

// // NotFound Component
// const NotFound = () => (
//   <div className="not-found sections">
//     <h1>404 - Not Found</h1>
//     <p>The page you are looking for does not exist.</p>
//   </div>
// );

// export default App;


import React, { useState, useEffect, useContext, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from './Home/ThemeContext';
import PreloaderCircle from './components/PreloaderCircle';
import ProtectedRoute from './ProtectedRoute';
const Header = React.lazy(() => import('./Home/Header'));
const Footer = React.lazy(() => import('./Home/Footer'));
const Main = React.lazy(() => import('./Home/HomeMain'));
const Login = React.lazy(() => import('./Home/Login'));
const MatchDetail = React.lazy(() => import('./Home/MatchDetails'));
const HomePost = React.lazy(() => import('./Home/HomeMain'));
const Register = React.lazy(() => import('./Home/Register'));
const Dashboard = React.lazy(() => import('./Admin/Dashboard'));
const Settings = React.lazy(() => import('./Admin/Settings'));
const Postlist = React.lazy(() => import('./Admin/Postlist'));
const Menulist = React.lazy(() => import('./Admin/Menulist'));
const Pagelist = React.lazy(() => import('./Admin/Pageslist'));
const Headerlist = React.lazy(() => import('./Admin/Headerlist.js'));
const SectionList = React.lazy(() => import('./Admin/SectionList.js'));
const Adslist = React.lazy(() => import('./Admin/Adslist.js'));
const Post = React.lazy(() => import('./Admin/Posts'));
const AdCreation = React.lazy(() => import('./Admin/AdCreation'));
const EditPost = React.lazy(() => import('./Admin/EditPost'));
const EditPage = React.lazy(() => import('./Admin/EditPage'));
const EditMenu = React.lazy(() => import('./Admin/EditMenu'));
const EditSection = React.lazy(() => import('./Admin/EditSection'));
const Users = React.lazy(() => import('./Admin/Users'));
const Pages = React.lazy(() => import('./Admin/Pages'));
const Menu = React.lazy(() => import('./Admin/Menu'));
const NotFoundPage = React.lazy(() => import('./Home/NotFoundPage'));
const AdminLayout = React.lazy(() => import('./Admin/AdminLayout'));
const HeaderManagement  = React.lazy(() => import('./Admin/HeaderManagement'));
const FooterManagement  = React.lazy(() => import('./Admin/FooterManagement'));
const ContentSectionManagement  = React.lazy(() => import('./Admin/ContentSectionManagement'));


const App = () => {
  
  return (
    <HelmetProvider>
      <ThemeProvider>
        <Router>
          
          
          <Routes>
              <Route path="/*" element={<Home />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/admin/*" element={<Admin />} />
            </Routes>
            
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};


const Home = () => (
  <>
    <Suspense>
      <Header/>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path=":title/" element={<HomePost />} />
          <Route path="/match/:id" element={<MatchDetail />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer/>
    </Suspense>
  </>
);

const Admin = () => (
  <>
    <Routes>
    <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute><AdminLayout /></ProtectedRoute>}>
        <Route path="/" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Dashboard /></Suspense></ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Settings /></Suspense></ProtectedRoute>} />
        <Route path="/ads" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Adslist/></Suspense></ProtectedRoute>} />
        <Route path="/add-ads" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><AdCreation /></Suspense></ProtectedRoute>} />
        {/* <Route path="/footer" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><FooterManagement /></Suspense></ProtectedRoute>} /> */}
        <Route path="/headers" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Headerlist /></Suspense></ProtectedRoute>} />
        <Route path="/add-header" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><HeaderManagement /></Suspense></ProtectedRoute>} />
        <Route path="/sections" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><SectionList/></Suspense></ProtectedRoute>} />
        <Route path="/add-section" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><ContentSectionManagement /></Suspense> </ProtectedRoute>} />
        <Route path="/pages" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Pagelist /></Suspense> </ProtectedRoute>} />
        <Route path="/add-menu" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Menu /></Suspense> </ProtectedRoute>} />
        <Route path="/add-page" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Pages /></Suspense> </ProtectedRoute>} />
        <Route path="/menus" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Menulist /></Suspense> </ProtectedRoute>} />
        <Route path="/posts" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Postlist /></Suspense> </ProtectedRoute>} />
        <Route path="/add-post" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Post /></Suspense> </ProtectedRoute>} />
        <Route path="/edit-page/:id" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><EditPage/></Suspense> </ProtectedRoute>} />
        <Route path="/edit-menu/:id" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><EditMenu /></Suspense> </ProtectedRoute>} />
        <Route path="/edit-post/:id" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><EditPost /></Suspense> </ProtectedRoute>} />
        <Route path="/edit-section/:id" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><EditSection /></Suspense> </ProtectedRoute>} />
        <Route path="/users" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><Users/></Suspense> </ProtectedRoute>} />
        <Route path="*" element={<ProtectedRoute><Suspense fallback={<PreloaderCircle />}><NotFoundPage /></Suspense> </ProtectedRoute>} />
      </Route>
    </Routes>
  </>
);

// NotFound Component
const NotFound = () => (
  <div className="not-found sections">
    <h1>404 - Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);

export default App;
