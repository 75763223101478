import React, { useEffect, useState } from 'react';

const PreloaderCircle = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 0);

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
        }
        return prevProgress + 10;
      });
    }, 0);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="preloader-circle">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="progress-bar">
            <div
              className="progress"
              style={{ width: `${progress}%` }}
            ></div>
            <div className="progress-text">{`${progress}%`}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PreloaderCircle;
